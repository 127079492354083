import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { HashLink as Link } from 'react-router-hash-link';

import RightArrowSVG from 'src/assets/icons/RightArrowSVG.svg';
import OrderForm from 'src/support/components/OrderForm';
import CloseCartSVG from 'src/assets/icons/CloseCartSVG.svg';

import './index.css';

export default ({ cart, deleteFromCart, product }) => {
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalSumm, setTotalSumm] = useState(0);
  const [totalPieces, setTotalPieces] = useState(0);
  const [blur, setBlur] = useState('');

  useEffect(() => {
    function handleTotalWeight(cart) {
      var weight = [];
      var totalWeight = 0;
      cart.forEach(product => {
        weight.push(product.weight);
      });
      totalWeight = weight.reduce((sum, current) => +sum + +current, 0);
      setTotalWeight(totalWeight);
    }
    function handleTotalSumm(cart) {
      var summ = [];
      var totalSumm = 0;

      cart.forEach(product => {
        summ.push(product.price * product.weight);
      });
      totalSumm = summ.reduce((sum, current) => sum + current, 0);

      setTotalSumm(totalSumm);
    }
    function handleTotalPieces(cart) {
      var pieces = [];
      var totalPieces = 0;

      cart.forEach(product => {
        pieces.push(product.pieces);
      });

      totalPieces = pieces.reduce((sum, current) => sum + current, 0);
      setTotalPieces(totalPieces);
    }
    handleTotalWeight(cart);
    handleTotalSumm(cart);
    handleTotalPieces(cart);
  }, [cart]);

  return (
    <div id="cart" className="cart">
      <Helmet>
        <title>
          Корзина - Динская Мельница - Пшеничная Мука оптом в Краснодаре!
        </title>
        <meta
          name="description"
          content='Здесь вы можете закончить оформление вашего заказа!Компания "Динская Мельница" - Собственное производство! 21 год на рынке! Подберём муку под ваше производство!'
        />
      </Helmet>
      {window.innerWidth < 600 && (
        <div className="cart__header">
          <h1 className="cart__title">Корзина</h1>

          <Link to="/#production-list">
            <CloseCartSVG className="cart__close" />
          </Link>
        </div>
      )}
      {window.innerWidth > 600 && cart.length > 0 && (
        <div className="cart__header">
          <Link
            style={{ display: 'flex', alignItems: 'center' }}
            to="/#production-list"
          >
            <h1 className="product__back-link__title--d">{product.category}</h1>
            <RightArrowSVG className="product__arrow" />
            <h1 className="product__back-link__title--d">{product.title}</h1>
          </Link>

          <RightArrowSVG className="product__arrow" />
          <h1
            style={{ color: 'black' }}
            className="product__back-link__title--d"
          >
            Корзина
          </h1>
        </div>
      )}

      {/* 
        {totalWeight < 1000 && window.innerWidth > 600 && (
          <>
          <h1 className="cart__warning--big">минимальный заказ 1000 кг </h1>
          </>
        )}
        */}
      <div className="cart__horizontal-cont">
        <div className="cart__prod-list">
          <h1
            style={{
              fontFamily: `${
                window.innerWidth > 600
                  ? 'Montserrat_Alternates_Medium'
                  : 'Montserrat_Alternates_Bold'
              }`,
            }}
            className="cart__title--big"
          >
            Выбранные товары
          </h1>
          {window.innerWidth > 600 && (
            <div className="cart__total-goods">
              Товары всего : {cart.length}
            </div>
          )}
          {cart.map((prod, i) => {
            return (
              <div>
                {window.innerWidth < 600 && (
                  <div className="popup__product-cont">
                    {prod.id === blur && (
                      <span className="popup__deleted"> Удалено</span>
                    )}
                    <div
                      className={`cart__prod ${
                        prod.id === blur ? 'cart__prod--blur' : ''
                      }`}
                      key={prod.id}
                    >
                      <span className="cart__prod-number">{i + 1}</span>
                      <div className="cart__prod__info-block">
                        <Link to={prod.link}>
                          <h1 className="cart__prod__title">{prod.title}</h1>
                        </Link>
                        <span className="cart__prod-char">
                          Вес: {prod.weight} кг
                        </span>
                        <span className="cart__prod-char">
                          Размер тары: {prod.volume} кг
                        </span>
                        <span className="cart__prod-char">
                          Количество: {prod.pieces} шт
                        </span>
                      </div>
                      <CloseCartSVG
                        onClick={e => {
                          setBlur(prod.id);
                          setTimeout(() => {
                            deleteFromCart({ id: prod.id });
                            setBlur('');
                          }, 800);
                        }}
                        className="cart__delete"
                      />
                    </div>
                  </div>
                )}

                {window.innerWidth > 600 && (
                  <div className="popup__product-cont">
                    {prod.id === blur && (
                      <span className="popup__deleted"> Удалено</span>
                    )}
                    <div
                      className={`popup__product--cart ${
                        prod.id === blur ? 'popup__product--cart--blur' : ''
                      }`}
                      key={prod.id}
                    >
                      <Link to={`${prod.link}#product`}>
                        <img
                          src={prod.product.thumbnail}
                          alt="product"
                          className="popup__product-photo--cart"
                        />
                      </Link>
                      <div className="popup__product__info-block">
                        <Link to={`${prod.link}#product`}>
                          <h1 className="popup__product-title">{prod.title}</h1>
                        </Link>
                        <span className="popup__product-char">
                          Размер тары: {prod.volume} кг
                        </span>
                        <span className="popup__product-char">
                          Вес: {prod.weight} кг
                        </span>
                        <span className="popup__product-char">
                          Количество: {prod.pieces} шт
                        </span>
                      </div>
                      <div className="popup__product__price-block">
                        <span className="popup__product-price">
                          {new Intl.NumberFormat().format(
                            prod.price * prod.weight,
                          )}
                          &nbsp; <span>&#8381;</span>
                        </span>

                        <button
                          onClick={e => {
                            setBlur(prod.id);
                            setTimeout(() => {
                              deleteFromCart({ id: prod.id });
                              setBlur('');
                            }, 800);
                          }}
                          className="popup__delete"
                        >
                          Удалить
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="cart__order-block">
          {/*        {totalWeight < 1000 && window.innerWidth < 600 ? (
            <>
              <span className="cart__warning">минимальный заказ 1000 кг</span>
            </>
          ) : (
            ''
          )} */}

          <div className="cart__total-block">
            <h1 className="cart__title--big">Общая сумма</h1>
            <div className="cart__total-char">
              <span className="cart__total-char-text">Общий вес </span>
              <span className="cart__total-char-text">
                {new Intl.NumberFormat().format(totalWeight)} кг
              </span>
            </div>
            <div className="cart__total-char">
              <span className="cart__total-char-text">Количество упаковок</span>
              <span className="cart__total-char-text">{totalPieces} шт </span>
            </div>
            <div className="cart__summ-block">
              <h1 className="cart__summ-title">СУММА</h1>
              <span className="cart__summ">
                {new Intl.NumberFormat().format(totalSumm)}
                &nbsp; <span style={{ fontFamily: 'Inter' }}>&#8381;</span>
              </span>
            </div>
          </div>
          <OrderForm {...{ totalWeight, totalSumm }} />
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Form } from 'formik';

import { Field } from 'formik';
import CloseCartSVG from 'src/assets/icons/CloseCartSVG.svg';
import CloseSVG from 'src/assets/icons/CloseSVG.svg';
import DoneSVG from 'src/assets/icons/DoneSVG.svg';
import logo from 'src/assets/icons/logo.png';

import smile from 'src/assets/icons/smile.webp';

import Snackbar from 'src/common/components/Snackbar';
import Spinner from 'src/common/components/Spinner';

import './index.css';

function OrderForm({
  totalWeight,
  totalSumm,
  errors,
  touched,
  values,
  complete,
  error,
  loading,
  dirty,
  isValid,
  onBlur,
}) {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    const handleSubmit = complete => {
      if (complete) {
        setPopup(true);
      }
    };
    handleSubmit(complete);
  }, [complete]);

  return (
    <div className="order-form-container">
      <h1 className="order__title">Заполните форму:</h1>
      <h1 className="order__subtitle">
        Для выставления счета бухгалтером или для оформления чека
      </h1>
      <Form className="order-form" action="email" method="POST">
        <div className="order-form__input-cont">
          <h1
            className={`order-form__input-title ${
              errors.fullName && touched.fullName
                ? 'order-form__input-title--err'
                : ''
            } `}
          >
            Имя
          </h1>
          {
            <h1 className="order-form__error">
              {touched.fullName && errors.fullName}
            </h1>
          }
          <Field
            type="text"
            value={values.fullName}
            name="fullName"
            className={`order-form__input ${
              errors.fullName && touched.fullName
                ? 'order-form__input--err'
                : ''
            } `}
          />
        </div>
        <div className="order-form__input-cont">
          <h1
            className={`order-form__input-title ${
              errors.phoneOrEmail && touched.phoneOrEmail
                ? 'order-form__input-title--err'
                : ''
            } `}
          >
            Номер телефона или email
          </h1>
          {
            <h1 className="order-form__error">
              {touched.phoneOrEmail && errors.phoneOrEmail}
            </h1>
          }
          <Field
            type="text"
            value={values.phoneOrEmail}
            name="phoneOrEmail"
            className={`order-form__input ${
              errors.phoneOrEmail && touched.phoneOrEmail
                ? 'order-form__input--err'
                : ''
            } `}
          />
        </div>

        <Spinner {...{ loading }} />

        {/* 
          
          {totalWeight < 1000 ? (
            <span className="order__warning">минимальный заказ 1000 кг</span>
          ) : (
            ''
          )}
          
          */}
        <button
          type="submit"
          className="order-form__submit"
          // disabled={totalWeight < 1000 || !(dirty && isValid)}
        >
          Заказать
        </button>
      </Form>

      {popup && (
        <div className="order-form__popup-cont">
          <div className="order-form__popup">
            {window.innerWidth < 600 ? (
              <CloseCartSVG
                className="order-form__popup-close"
                onClick={() => {
                  setPopup(false);
                }}
              />
            ) : (
              <CloseSVG
                className="order-form__popup-close"
                onClick={() => {
                  setPopup(false);
                }}
              />
            )}
            {window.innerWidth < 600 ? (
              <DoneSVG className="order-form__popup-done" />
            ) : (
              <img src={logo} alt="logo" />
            )}
            <div className="order-form__popup__title-cont">
              Спасибо за заказ!
              {window.innerWidth < 600 && (
                <img src={smile} alt="smile" className="order-form__smile" />
              )}
            </div>
            <span className="order-form__popup__subtitle">
              Наш бухгалтер свяжется с Вами в ближайшее время!
            </span>

            <button
              className="order-form__popup-back"
              onClick={() => {
                setPopup(false);
              }}
            >
              Вернуться на сайт
            </button>
          </div>
        </div>
      )}
      {error && <Snackbar variant="error" text="Что-то пошло не так..." />}
    </div>
  );
}

export default OrderForm;
